import { Add } from '@mui/icons-material';
import { Container, Grid, Icon } from '@mui/material';
import CompanySearch from 'components/CompanySearch';
import TitleCard from 'components/TitleCard';
import { useEffect, useState } from 'react';
import DashboardCard from 'components/DashboardCard';
import { get } from 'lib/api';
import DebtAmountCard from './DebtAmountCard';
import DebtCountCard from './DebtCountCard';

export default function Dashboard() {
  const [debts, setDebts] = useState(null);

  useEffect(() => {
    if (debts) return;
    (async () => {
      const { json } = await get('debts/list');
      setDebts(json);
    })();
  }, [debts]);

  const doSearch = (company) => {
    alert(`Now it's time to do a debt search for ${company?.name}!`);
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitleCard title="Company Search" item sx={{ minHeight: 300 }}>
            <CompanySearch onConfirm={doSearch} />
          </TitleCard>
        </Grid>

        <DebtCountCard debts={debts} />

        <DebtAmountCard debts={debts} />

        <DashboardCard title="In Collections" xs={12} md={6} lg={3}>
          0
        </DashboardCard>

        <DashboardCard title="Register Debt" link="/debts/register" xs={12} md={6} lg={3}>
          <Icon sx={{ fontSize: '64px !important' }}>
            <Add />
          </Icon>
        </DashboardCard>
      </Grid>
    </Container>
  );
}
