import { Button, Grid, TextField, Typography } from '@mui/material';
import AddressForm from 'components/AddressForm';
import Page from 'components/Page';
import { Field, Form, Formik } from 'formik';
import { post } from 'lib/api';
import { useState } from 'react';

export default function Register() {
  const [done, setDone] = useState(false);

  const doRegister = async (values) => {
    const { res } = await post('users/create', values);
    if (res.status === 200) {
      setDone(true);
    }
  };

  if (done)
    return (
      <Page title="Registration Complete">
        <Typography variant="body1" textAlign="center">
          Your account has been created. Please check your email for instructions to activate it.
        </Typography>
      </Page>
    );

  return (
    <Page title="New Account Registration" maxWidth="md">
      <Formik initialValues={{ country: 'ie' }} onSubmit={doRegister}>
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Typography variant="caption">Personal Information</Typography>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field as={TextField} fullWidth label="First Name" name="first_name" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field as={TextField} fullWidth label="Last Name" name="last_name" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field as={TextField} fullWidth label="Email" name="email" />
              </Grid>

              <Grid item xs={12} lg={6}>
                <Field as={TextField} fullWidth label="Password" name="password" type="password" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field as={TextField} fullWidth label="Retype Password" name="password_confirmation" type="password" />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="caption">Company Address</Typography>
              </Grid>
              <AddressForm />

              <Grid item xs={12} lg={4}>
                <Button fullWidth variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Working' : 'Register'}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Page>
  );
}
