import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Page from 'components/Page';
import { get } from 'lib/api';

export default function ViewDebt(props) {
  const { debtId } = useParams();
  const [debt, setDebt] = useState(null);

  useEffect(() => {
    if (debt) return;
    (async () => {
      const { json } = await get('debts/get', { id: debtId });
      setDebt(json);
    })();
  }, [debt, debtId]);

  return (
    <Page title="Debt Details">
      Details page for a debt. ID: {debtId}
      <pre style={{ textAlign: 'left' }}>{JSON.stringify(debt, null, 3)}</pre>
    </Page>
  );
}
