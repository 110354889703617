import React from 'react';
import { useUser } from 'lib/user';
import Login from 'views/Guest/Login';
import Register from 'views/Guest/Register';
import Main from 'views/Main';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Dashboard from 'views/Main/Dashboard';
import ListDebt from 'views/Main/ListDebt';
import UserProfile from 'views/Main/Profile';
import RegisterDebt from 'views/Main/RegisterDebt';
import ViewDebt from 'views/Main/ViewDebt';

const Admin = React.lazy(() => import('views/Admin'));

function App() {
  const { tried, user } = useUser();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (!tried) return <></>;

  if (!user && !['/login', '/register', '/activate', '/reset'].includes(pathname)) {
    navigate('/login');
    return <></>;
  }

  return (
    <Routes>
      <Route path="/" element={<Main />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {user && (
          <>
            <Route path="/" element={<Dashboard />} />
            <Route path="/debts/list" element={<ListDebt />} />
            <Route path="/debts/register" element={<RegisterDebt />} />
            <Route path="/debts/view/:debtId" element={<ViewDebt />} />
            <Route path="/profile" element={<UserProfile />} />
          </>
        )}
      </Route>
      {user?.admin && (
        <Route
          path="/admin"
          element={
            <React.Suspense fallback={<>...</>}>
              <Admin />
            </React.Suspense>
          }
        />
      )}
    </Routes>
  );
}

export default App;
