import { Stack } from '@mui/material';

export default function FormActions(props) {
  const { children, justifyContent = 'flex-end' } = props;

  const style = { width: '100%', paddingTop: 2, '& > *': { marginLeft: 2 } };
  return (
    <Stack direction="row" sx={style} justifyContent={justifyContent}>
      {children}
    </Stack>
  );
}
