import { MainNav } from 'components/MainNav';
import { GuestNav } from 'components/GuestNav';
import { useUser } from 'lib/user';
import { Outlet } from 'react-router-dom';

export default function Main() {
  const { user } = useUser();
  return (
    <>
      {user ? <MainNav /> : <GuestNav />}
      <Outlet />
    </>
  );
}
