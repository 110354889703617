export const user = {
  id: '',
  email: '',
  password: '',
  admin: '',
  first_name: '',
  last_name: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: 'ie',
  postal: '',
  phone: '',
  reg_number: '',
  reg_type: 'cro',
  activated: '',
  activated_at: '',
  locked: ''
};
