import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import CompanySearch from 'components/CompanySearch';
import FormActions from 'components/FormActions';
import InvoiceList from 'components/InvoiceList';
import { post } from 'lib/api';
import { useState } from 'react';
import Page from 'components/Page';
import { noop } from 'lodash';

function LookupCompanyStep(props) {
  const { onNext } = props;

  return <CompanySearch onConfirm={(company) => onNext(company)} />;
}

function DetailsStep(props) {
  const { debt, onChange = noop } = props;

  const toggle = (key) => onChange({ ...debt, [key]: !debt[key] });

  if (!debt?.id) return null;

  return (
    <>
      <Typography variant="body1">Please provide the invoices associated with this debt.</Typography>

      <InvoiceList debt={debt} onChange={onChange} />

      <FormControl fullWidth>
        <InputLabel>Currency</InputLabel>
        <Select
          fullWidth
          label="Currency"
          value={debt.currency}
          onChange={(e) => onChange({ ...debt, currency: e.target.value })}
        >
          <MenuItem value="eur">Euro</MenuItem>
          <MenuItem value="gbp">GBP</MenuItem>
          <MenuItem value="usd">USD</MenuItem>
        </Select>
      </FormControl>

      <Typography variant="caption">
        All invoices in a debt listing must be of the same currency. If you have multiple currencies, please create
        separate listings.
      </Typography>

      <Grid container spacing={1} sx={{ mt: 4 }}>
        <Grid item xs={12} md={6}>
          Oldest invoice due date
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Payment Status</InputLabel>
            <Select
              fullWidth
              label="Currency"
              value={debt.payment_status}
              onChange={(e) => onChange({ ...debt, payment_status: e.target.value })}
            >
              <MenuItem value="outstanding">Outstanding</MenuItem>
              <MenuItem value="partial">Partial</MenuItem>
            </Select>
          </FormControl>{' '}
        </Grid>
      </Grid>

      <Stack sx={{ mt: 4 }}>
        <FormControlLabel
          onChange={() => toggle('dispute')}
          control={<Checkbox sx={{ fontSize: 22 }} checked={debt.dispute} />}
          label={<span>In Dispute</span>}
        />
        <FormControlLabel
          onChange={() => toggle('proceedings')}
          control={<Checkbox sx={{ fontSize: 22 }} checked={debt.proceedings} />}
          label={<span>Legal Proceedings Taken</span>}
        />
        <FormControlLabel
          onChange={() => toggle('judgement')}
          control={<Checkbox sx={{ fontSize: 22 }} checked={debt.judgement} />}
          label={<span>Judgement Obtained</span>}
        />
      </Stack>
    </>
  );
}

function CompleteStep(props) {
  const { onNext, onPrev } = props;
  const [agreed, setAgreed] = useState(false);
  const [working, setWorking] = useState(false);
  const disabled = !agreed || working;

  const onSubmit = async () => {
    // setWorking(true);
    onNext();
  };

  return (
    <>
      <Typography variant="body1">
        Your payment method will now be charged. By continuing, you agree to the{' '}
        <a
          href="http://www.businessdebtregister.com/terms-and-conditions/"
          target="_blank"
          rel="noreferrer"
          className="text-link"
        >
          Terms and Conditions
        </a>{' '}
        authorize us to charge your payment method on file.
      </Typography>

      <br />

      <FormControlLabel
        onChange={() => setAgreed(!agreed)}
        control={<Checkbox sx={{ fontSize: 22 }} checked={agreed} />}
        label={<span>I agree to the Terms and Conditions</span>}
      />

      <FormActions justifyContent="center">
        <Button onClick={onPrev}>Back</Button>
        <Button variant="contained" color="primary" onClick={onSubmit} disabled={disabled}>
          Register Debt
        </Button>
      </FormActions>
    </>
  );
}

export default function RegisterDebt(props) {
  const [active, setActive] = useState(0);
  const [company, setCompany] = useState(null);
  const [debt, setDebt] = useState({});

  const createDraft = async (chosenCompany) => {
    if (company && company.id === chosenCompany.id && debt) {
      // going back, draft already exists, and company wasn't changed
      setActive(1);
      return;
    }
    setCompany(chosenCompany);
    const { json } = await post('debts/create', {
      company: chosenCompany.id,
      status: 'draft',
      amount: 0,
      currency: 'eur',
      dispute: false,
      proceedings: false,
      judgement: false,
      oldest_due_date: '2022-03-09', //new Date().toISOString(),
      payment_status: 'outstanding'
    });
    setDebt(json);
    setActive(1);
  };

  const submitDebt = async () => {
    console.log(debt);
    const { res } = await post('debts/submit', debt);
    console.log(res);
  };

  return (
    <Page title="Register a Debt" maxWidth="md">
      <Stepper activeStep={active} orientation="vertical" sx={{ '& .MuiStepLabel-iconContainer': { fontSize: 24 } }}>
        <Step key={0}>
          <StepLabel>Look up company</StepLabel>
          <StepContent TransitionProps={{ unmountOnExit: false }}>
            <LookupCompanyStep onNext={createDraft} />
          </StepContent>
        </Step>
        <Step key={1}>
          <StepLabel>Provide Documentation</StepLabel>
          <StepContent TransitionProps={{ unmountOnExit: false }} key={debt.id}>
            <DetailsStep debt={debt} company={company} onChange={setDebt} />
            <FormActions>
              <Button onClick={() => setActive(0)}>Back</Button>
              <Button variant="contained" color="primary" onClick={() => setActive(2)}>
                Next
              </Button>
            </FormActions>
          </StepContent>
        </Step>
        <Step key={2}>
          <StepLabel>Complete listing</StepLabel>
          <StepContent TransitionProps={{ unmountOnExit: false }} key={debt.id}>
            <CompleteStep onNext={submitDebt} onPrev={() => setActive(1)} />
          </StepContent>
        </Step>
      </Stepper>
    </Page>
  );
}
