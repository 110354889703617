import { Box, Paper, Typography } from '@mui/material';
import { useUser } from 'lib/user';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoginForm from 'views/Login/LoginForm';

export default function Login() {
  const { login } = useUser();
  const navigate = useNavigate();
  const [failed, setFailed] = useState(false);

  const doLogin = async ({ email, password }) => {
    const { res } = await login(email, password);
    if (res.status === 200) {
      navigate('/');
    } else {
      setFailed(true);
    }
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexFlow: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Paper sx={{ padding: 2, maxWidth: 400, marginTop: '-20%' }} elevation={4}>
        <Box sx={{ textAlign: 'center', pb: 1, color: 'red', visibility: failed ? 'visible' : 'hidden' }}>
          <Typography variant="caption" component="div">
            Username or password incorrect.
          </Typography>
        </Box>
        <LoginForm onLogin={doLogin} />
        <Box sx={{ textAlign: 'center', pt: 3 }}>
          <Typography variant="caption" component="div">
            Forgot your password?{' '}
            <Link to="/reset" className="text-link">
              Reset it here.
            </Link>
          </Typography>
          <Typography variant="caption" component="div">
            Need an account?{' '}
            <Link to="/register" className="text-link">
              Register here.
            </Link>
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
}
