import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Field, useFormikContext } from 'formik';

const typeLabels = {
  cro: 'CRO #',
  business: 'Business #'
};

export default function AddressForm() {
  const { values } = useFormikContext();
  const regTypeLabel = typeLabels[values?.reg_type || 'cro'];

  return (
    <>
      <Grid item xs={12} lg={12}>
        <Field as={TextField} fullWidth label="Address Line 1" name="address1" />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Field as={TextField} fullWidth label="Address Line 2" name="address2" />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Field as={TextField} fullWidth label="Phone" name="phone" inputProps={{ maxLength: 20 }} />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Field as={TextField} fullWidth label="City" name="city" />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Field as={TextField} fullWidth label="State/Province" name="state" />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Field as={TextField} fullWidth label="Postal Code" name="postal" />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Field name="country">
          {({ field }) => (
            <FormControl fullWidth>
              <InputLabel>Country</InputLabel>
              <Select fullWidth label="Country" {...field}>
                <MenuItem value="ie">Ireland</MenuItem>
              </Select>
            </FormControl>
          )}
        </Field>
      </Grid>

      <Grid item xs={12} lg={6}>
        <Field name="reg_type">
          {({ field }) => (
            <FormControl fullWidth>
              <InputLabel>Registration Type</InputLabel>
              <Select fullWidth label="Registration Type" {...field}>
                <MenuItem value="cro">CRO</MenuItem>
                <MenuItem value="business">Business Registration</MenuItem>
              </Select>
            </FormControl>
          )}
        </Field>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Field as={TextField} fullWidth label={regTypeLabel} name="reg_number" />
      </Grid>
    </>
  );
}
