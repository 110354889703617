import { CameraAlt, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Grid,
  Input,
  TextField,
  Typography
} from '@mui/material';
import Uppy from '@uppy/core';
import { useUppy } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import AddressForm from 'components/AddressForm';
import PaymentMethod from 'components/PaymentMethod';
import { Field, Form, Formik } from 'formik';
import { endpoint, post } from 'lib/api';
import { useUser } from 'lib/user';
import React, { useState } from 'react';
import { user as defaultUser } from 'lib/defaultFormValues';

function PhotoUploader(props) {
  const { user } = props;
  const { refresh } = useUser();

  const uppy = useUppy(() => {
    return new Uppy({
      id: 'photo_uploader',
      autoProceed: true,
      meta: {
        target_type: 'user',
        target_id: user.id
      }
    })
      .use(XHRUpload, {
        endpoint: endpoint + '/files/upload.php',
        withCredentials: true
      })
      .on('upload-success', () => {
        refresh();
      });
  });

  const selectFile = (e) => {
    const file = e.target.files[0];
    uppy.addFile({
      source: 'file input',
      name: file.name,
      type: file.type,
      data: file
    });
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          height: 200,
          marginBottom: 20,
          overflow: 'hidden',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${endpoint}/files/view.php/${user.photo_file_uuid})`
        }}
      />
      <label htmlFor="photo_input">
        <Input type="file" multiple accept="image/*" id="photo_input" sx={{ display: 'none' }} onChange={selectFile} />
        <Button variant="outlined" fullWidth startIcon={<CameraAlt />} component="span">
          Upload Photo
        </Button>
      </label>
    </>
  );
}

function Pane(props) {
  const { user, onSubmit, title, children, ...otherProps } = props;
  return (
    <Accordion {...otherProps}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

function FormPane(props) {
  const { saveButtonLabel = 'Update', children, onSubmit, user, ...otherProps } = props;
  return (
    <Pane {...otherProps}>
      <Formik initialValues={{ ...defaultUser, ...user }} enableReinitialize onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={2} justifyContent="center">
              {children}

              <Grid item xs={12} lg={4}>
                <Button fullWidth variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Working' : saveButtonLabel}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Pane>
  );
}

export default function UserProfile() {
  const { user, refresh } = useUser();
  const [panel, setPanel] = useState(0);

  const updateProfile = async (values) => {
    const payload = {};
    Object.keys(values).forEach((key) => {
      if (values[key]?.length) payload[key] = values[key];
    });
    await post('users/update', payload);
    await refresh();
  };

  return (
    <Container maxWidth="lg">
      <FormPane
        title="Profile"
        expanded={panel === 0}
        onChange={() => setPanel(0)}
        user={user}
        onSubmit={updateProfile}
      >
        <>
          <Grid item xs={12} lg={9}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Typography variant="caption">Personal Information</Typography>
              </Grid>
              <Grid item xs={12}>
                <Field as={TextField} fullWidth label="First Name" name="first_name" />
              </Grid>
              <Grid item xs={12}>
                <Field as={TextField} fullWidth label="Last Name" name="last_name" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={3}>
            <PhotoUploader user={user} />
          </Grid>
        </>
      </FormPane>

      <FormPane
        title="Address"
        expanded={panel === 1}
        onChange={() => setPanel(1)}
        user={user}
        onSubmit={updateProfile}
      >
        <AddressForm />
      </FormPane>

      <Pane title="Payment Info" expanded={panel === 2} onChange={() => setPanel(2)}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} lg={4}>
            <PaymentMethod />
          </Grid>
        </Grid>
      </Pane>
    </Container>
  );
}
