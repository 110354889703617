import { Add, CheckCircleOutline, HighlightOffOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Input,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Uppy from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import { useUppy } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import CurrencyField from 'components/CurrencyField';
import { endpoint } from 'lib/api';
import { noop, reduce } from 'lodash';
import { useState } from 'react';
import NumberFormat from 'react-number-format';

export default function InvoiceList(props) {
  const { debt, onChange = noop } = props;
  const [files, setFiles] = useState([]);
  const [total, setTotal] = useState(0);
  const uppy = useUppy(() => {
    return new Uppy({
      id: 'invoice_uploader',
      autoProceed: true,
      meta: {
        target_type: 'debt',
        target_id: debt.id
      }
    })
      .use(XHRUpload, {
        endpoint: endpoint + '/files/upload.php',
        withCredentials: true
      })
      .on('upload-success', (file, res) => {
        setFiles((oldFiles) => {
          const newFiles = [...oldFiles];
          const index = file.meta.index;
          const uuid = res.body.uuid;
          newFiles[index] = { ...newFiles[index], uuid, loading: false };
          return newFiles;
        });
      });
  });

  const addItem = (e) => {
    const file = e.target.files[0];
    uppy.addFile({
      source: 'file input',
      name: file.name,
      type: file.type,
      data: file,
      meta: {
        index: files.length
      }
    });
    setFiles([...files, { amount: 0, name: file.name, loading: true }]);
  };

  const removeItem = (i) => setFiles(files.filter((f, j) => i !== j));

  const setAmount =
    (i) =>
    ({ value }) => {
      const newFiles = [...files];
      newFiles[i] = { ...newFiles[i], amount: value };
      setFiles(newFiles);
      setTotal(reduce(newFiles, (a, i) => a + parseFloat(i.amount), 0));
    };

  return (
    <>
      <Table sx={{ mb: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>File</TableCell>
            <TableCell colSpan={2}>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file, i) => (
            <TableRow key={i}>
              <TableCell sx={{ width: '60%' }}>
                {!file.uuid && (
                  <ListItem>
                    <ListItemIcon sx={{ fontSize: 24 }}>
                      <CircularProgress size={24} />
                    </ListItemIcon>
                    <ListItemText>{file.name}</ListItemText>
                  </ListItem>
                )}
                {file.uuid && (
                  <ListItem>
                    <ListItemIcon sx={{ fontSize: 24 }}>
                      <CheckCircleOutline />
                    </ListItemIcon>
                    <ListItemText>
                      <a href={`${endpoint}/files/view.php/${file.uuid}`} target="_blank" rel="noreferrer">
                        {file.name}
                      </a>
                    </ListItemText>
                  </ListItem>
                )}
              </TableCell>
              <TableCell>
                <CurrencyField
                  fullWidth
                  size="small"
                  value={file.amount}
                  onValueChange={setAmount(i)}
                  onClick={(e) => e.target.select()}
                />
              </TableCell>
              <TableCell sx={{ width: 40 }}>
                <IconButton onClick={() => removeItem(i)}>
                  <HighlightOffOutlined />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <label htmlFor="invoice_add">
        <Input type="file" multiple accept="image/*" id="invoice_add" sx={{ display: 'none' }} onChange={addItem} />
        <Button variant="outlined" fullWidth startIcon={<Add />} component="span">
          Add Invoice
        </Button>
      </label>

      <Box sx={{ py: 4 }}>
        <Typography>
          Total debt amount:{' '}
          <NumberFormat
            style={{ fontWeight: 'bold' }}
            displayType="text"
            value={total}
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
            fixedDecimalScale
          />
        </Typography>
      </Box>
    </>
  );
}
