import { Button, Stack, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';

export default function LoginForm(props) {
  const { onLogin } = props;

  return (
    <Formik initialValues={{ email: '', password: '' }} onSubmit={onLogin}>
      {({ values }) => (
        <Form>
          <Stack spacing={2} alignItems="center" sx={{ minWidth: 300 }}>
            <Field as={TextField} fullWidth label="Email" name="email" />
            <Field as={TextField} fullWidth label="Password" name="password" type="password" />
            <Button variant="contained" color="primary" type="submit" disabled={!values.email && !values.password}>
              Log In
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
