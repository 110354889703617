import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const cardProps = {
  sx: {
    padding: 2,
    paddingTop: 0,
    minHeight: 140,
    textAlign: 'center',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'stretch',
    '& svg': {
      fontSize: 'inherit'
    }
  },
  elevation: 3
};

const mainBoxProps = {
  sx: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 64
  }
};

export default function DashboardCard(props) {
  const { children, title, link, ...gridProps } = props;

  const item = (
    <Card {...cardProps}>
      <Box {...mainBoxProps}>{children}</Box>
      <Divider />
      <Typography variant="h5" sx={{ flexGrow: 0, marginTop: 1 }}>
        {title}
      </Typography>
    </Card>
  );

  if (link)
    return (
      <Grid item {...gridProps}>
        <Link to={link}>{item}</Link>
      </Grid>
    );

  return (
    <Grid item {...gridProps}>
      {item}
    </Grid>
  );
}
