import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Page from 'components/Page';
import { get } from 'lib/api';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ListDebt(props) {
  const navigate = useNavigate();
  const [debts, setDebts] = useState(null);

  useEffect(() => {
    if (debts) return;
    (async () => {
      const { json } = await get('debts/list');
      setDebts(json);
    })();
  }, [debts]);

  return (
    <Page title="Submitted Debts" sx={{ height: '80vh' }}>
      {debts && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell>CRO#</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {debts.map((debt, i) => (
              <TableRow key={i} onClick={() => navigate(`/debts/view/${debt.id}`)} style={{ cursor: 'pointer' }}>
                <TableCell>{debt.company_name}</TableCell>
                <TableCell>{debt.cro}</TableCell>
                <TableCell>{debt.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Page>
  );
}
