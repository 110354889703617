const configs = {
  development: {
    debug: true,
    stripe: {
      publishableKey: 'pk_test_kFaLytvG5xPp3FkY3ZaY6fCB00gXBS783v'
    }
  }
};

const domains = {
  'bdrdev.ishmayeck.net': 'development',
  default: 'development'
};

const configType = domains[window.location.hostname] || domains['default'];
export const config = configs[configType];
