import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export function GuestNav() {
  return (
    <>
      <AppBar>
        <Toolbar>
          <Link to="/">
            <Typography variant="h6">Business Debt Register</Typography>
          </Link>
          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>
      </AppBar>
    </>
  );
}
