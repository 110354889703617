import { Container } from '@mui/material';
import TitleCard from 'components/TitleCard';

export default function Page(props) {
  const { children, title, sx, maxWidth = 'lg' } = props;

  return (
    <Container {...{ maxWidth }}>
      <TitleCard {...{ title, sx }}>{children}</TitleCard>
    </Container>
  );
}
