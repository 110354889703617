import { Box, Card, Divider, Stack, Typography } from '@mui/material';

const cardProps = {
  sx: {
    padding: 2,
    paddingTop: 0,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    '& svg': {
      fontSize: 'inherit'
    }
  },
  elevation: 3
};

export default function TitleCard(props) {
  const { children, title, sx } = props;

  return (
    <Card {...cardProps}>
      <Stack spacing={2} sx={{ width: '100%', marginBottom: 2 }}>
        <Typography variant="h5" sx={{ marginTop: 2, textAlign: 'center' }}>
          {title}
        </Typography>
        <Divider />
      </Stack>
      <Box sx={{ width: '100%', ...sx }}>{children}</Box>
    </Card>
  );
}
