import { Button, Stack } from '@mui/material';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { post } from 'lib/api';
import { useUser } from 'lib/user';
import React, { useEffect, useState } from 'react';
import { config } from 'config.js';

const stripePromise = loadStripe(config.stripe.publishableKey);

function Stripe(props) {
  const { children, paymentSettings } = props;

  const stripeOpts = { clientSecret: paymentSettings?.setup_intent_client_secret };

  return (
    <Elements stripe={stripePromise} options={stripeOpts}>
      {children}
    </Elements>
  );
}

function PaymentMethodForm(props) {
  const { paymentSettings } = props;
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: 'http://bdrdev.ishmayeck.net:3000/api/users/set_payment_intent.php'
      }
    });

    console.log(res);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <PaymentElement />
        <Button fullWidth variant="contained" color="primary" type="submit">
          Save Payment Info
        </Button>
      </Stack>
    </form>
  );
}

export default function PaymentMethod(props) {
  const [payment, setPayment] = useState(null);
  const { user } = useUser();

  useEffect(() => {
    if (payment) return;
    (async () => {
      const { json } = await post('users/payment_settings', { id: user.id });
      setPayment(json);
    })();
  }, [payment, user.id]);

  if (!payment) return null;

  return (
    <Stripe paymentSettings={payment}>
      <PaymentMethodForm />
    </Stripe>
  );
}
