import { TextField } from '@mui/material';
import NumberFormat from 'react-number-format';

export default function CurrencyField(props) {
  const { children, ...otherProps } = props;

  return (
    <NumberFormat
      customInput={TextField}
      thousandSeparator=","
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale
      onClick={(e) => e.target.select()}
      {...otherProps}
    >
      {children}
    </NumberFormat>
  );
}
