import {
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Radio,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import FormActions from 'components/FormActions';
import { post } from 'lib/api';
import { useState } from 'react';
import { Formik, Form, Field } from 'formik';

export default function CompanySearch(props) {
  const { onConfirm } = props;
  const [companies, setCompanies] = useState(null);
  const [company, setCompany] = useState(null);

  if (companies)
    return (
      <>
        {companies && !companies.length && <NoResults />}
        {companies?.length > 1 && <CompanyChooser companies={companies} onChoose={(i) => setCompany(companies[i])} />}
        {companies?.length === 1 && <CompanySingle companies={companies} />}
        <FormActions>
          <Button onClick={() => setCompanies(null)}>Search Again</Button>
          <Button variant="contained" onClick={() => onConfirm(company)} disabled={!company}>
            Continue
          </Button>
        </FormActions>
      </>
    );

  return (
    <CompanySearchForm
      onResults={(r) => {
        setCompanies(r);
        if (r?.length === 1) setCompany(r[0]);
      }}
    />
  );
}

function CompanyChooser(props) {
  const { companies, onChoose } = props;
  const [selected, setSelected] = useState(null);

  const choose = (i) => {
    setSelected(i);
    onChoose(i);
  };

  return (
    <List dense>
      <ListSubheader>Search Results</ListSubheader>
      <Divider />
      {companies.map((c, i) => (
        <ListItem key={c.id} disablePadding divider>
          <ListItemButton onClick={() => choose(i)}>
            <ListItemIcon style={{ fontSize: '20px' }}>
              <Radio edge="start" checked={selected === i} />
            </ListItemIcon>
            <ListItemText primary={c.name} secondary={`${c.reg_type} #${c.reg_number}`} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

function CompanySingle(props) {
  const { companies } = props;

  return (
    <List dense>
      <ListSubheader>Search Results</ListSubheader>
      <Divider />
      {companies.map((c) => (
        <ListItem key={c.id} disablePadding divider>
          <ListItemText primary={c.name} secondary={`${c.reg_type} #${c.reg_number}`} />
        </ListItem>
      ))}
    </List>
  );
}

function NoResults() {
  return (
    <List>
      <ListSubheader>Search Results</ListSubheader>
      <Divider />
      <ListItem divider>
        <ListItemText secondary="No results found for your query. Please try again." sx={{ textAlign: 'center' }} />
      </ListItem>
    </List>
  );
}

function CompanySearchForm(props) {
  const { onResults } = props;
  const [loading, setLoading] = useState(false);

  const sx = {
    padding: 3,
    maxWidth: 500,
    width: '100%',
    minHeight: 220,
    margin: 'auto',
    textAlign: 'center'
  };

  const submit = async (values) => {
    setLoading(true);
    const { json } = await post('companies/search', values);
    setLoading(false);
    onResults(json);
  };

  if (loading) {
    return (
      <Box sx={{ ...sx, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
        <Typography variant="caption" sx={{ pt: 3 }}>
          Searching company registration database...
        </Typography>
      </Box>
    );
  }

  return (
    <Formik initialValues={{ name: '', reg_type: 'cro', reg_number: '' }} onSubmit={submit}>
      {({ values }) => (
        <Form>
          <Stack spacing={2} sx={sx}>
            <Field as={TextField} fullWidth label="Company Name" name="name" />
            <Typography variant="button">OR</Typography>
            <Field as={TextField} fullWidth label="CRO#" name="reg_number" />
            <Button variant="contained" color="primary" type="submit" disabled={!values.name && !values.reg_number}>
              Search
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
