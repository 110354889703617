import DashboardCard from 'components/DashboardCard';
import { reduce } from 'lodash';

export default function DebtAmountCard(props) {
  const { debts = [] } = props;

  const amt = reduce(debts, (a, v) => a + Number(v.amount), 0);

  return (
    <DashboardCard title="Outstanding Amount" xs={12} md={6} lg={3} link="debts/list">
      €{amt}
    </DashboardCard>
  );
}
