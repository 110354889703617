import DashboardCard from 'components/DashboardCard';

export default function DebtCountCard(props) {
  const { debts } = props;

  return (
    <DashboardCard title="Total Debts" xs={12} md={6} lg={3} link="debts/list">
      {debts?.length}
    </DashboardCard>
  );
}
