import { AppBar, Box, Button, Divider, Menu, MenuItem, MenuList, Toolbar, Typography } from '@mui/material';
import UserAvatar from 'components/UserAvatar';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from 'lib/user';

export function MainNav() {
  const { user, logout } = useUser();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const name = `${user.first_name || ''}${user.first_name ? ' ' : ''}${user.last_name}`;

  return (
    <>
      <AppBar>
        <Toolbar>
          <Link to="/">
            <Typography variant="h6">Business Debt Register</Typography>
          </Link>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ flexGrow: 1 }} />
          <Button color="inherit" onClick={(e) => setMenuAnchor(e.target)} startIcon={<UserAvatar user={user} />}>
            {name || user.email}
          </Button>
          <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
            <MenuList>
              {Boolean(user.admin) && (
                <>
                  <MenuItem component={Link} to="/admin">
                    Go to admin portal
                  </MenuItem>
                  <Divider />
                </>
              )}
              <MenuItem component={Link} to="/profile">
                Edit profile
              </MenuItem>
              <MenuItem onClick={logout}>Log Out</MenuItem>
            </MenuList>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
}
